<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <div>
          <h1 class="page-title">
            <a-icon type="arrow-left" @click="goBack" />
            Form Create Manual Order
          </h1>
        </div>
      </div>
    </div>

    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :class="{ info: isDetail }"
      @submit="onSubmit"
      @submit.native.prevent
    >
      <!-- Detail -->
      <a-card title="Data Detail Transaksi" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="Order Number" prop="orderNumber">
              <a-input
                v-model="form.orderNumber"
                size="large"
                autocomplete="off"
                placeholder="Masukan nomor order"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="Gudang" prop="warehouseId" class="mb-0">
              <a-select
                v-model="form.warehouseId"
                size="large"
                class="select-antd-default"
                @change="onChangeWarehouse"
              >
                <a-select-option
                  v-for="item in warehouseList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="Tanggal" prop="orderDate">
              <a-date-picker
                v-model="form.orderDate"
                show-time
                size="large"
                class="w-100"
                placeholder="Masukan tanggal order"
                :format="dateFormat"
                :disabled-date="disabledDate"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="Metode Pembayaran" prop="paymentType" class="mb-0">
              <a-select
                v-model="form.paymentType"
                size="large"
                class="select-antd-default"
                placeholder="Sudah Lunas / Belum Lunas"
              >
                <a-select-option
                  v-for="item in paymentList"
                  :key="item.method"
                  :value="item.method"
                >
                  {{ item.payment_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>

      <!-- Detail Item Produk -->
      <a-card title="Keranjang Transaksi" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="24">
            <div>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="d-flex">
                  <a-button
                    type="primary"
                    :disabled="!form.warehouseId || !inventories.length"
                    @click="addItem"
                  >
                    Tambah Item
                  </a-button>
                  <a-button
                    class="ml-2"
                    type="primary"
                    :disabled="!form.warehouseId || !inventories.length"
                    @click="showModalBulkItemImport = true"
                  >
                    Bulk Item Import
                  </a-button>
                  <a-alert
                    v-if="form.warehouseId && !inventories.length && !search"
                    class="ml-2"
                    style="font-size: 13px"
                    message="Tidak ada produk terdaftar di gudang yg anda pilih"
                    type="warning"
                    show-icon
                  />
                </div>
                <div>Total SKU: <b>{{ computedValidSKU || 0 }}</b></div>
              </div>
              <a-table
                :columns="columns"
                :row-key="(record, index) => index"
                :data-source="itemList"
                :loading="isLoading"
                :pagination="false"
                :scroll="{ y: 330, x: 1300 }"
              >
                <template slot="action1" slot-scope="text, record">
                  <div
                    @click="onDeleteItem(record)"
                  >
                    <TrashIcon style="color: #E00000; cursor:pointer" />
                  </div>
                </template>
                <template slot="sku" slot-scope="text, record">
                  <a-select
                    v-model="record.catalog_id"
                    size="large"
                    class="select-antd-default"
                    style="width: 100%"
                    placeholder="Produk"
                    show-search
                    :label-in-value="false"
                    :not-found-content="fetching ? undefined : null"
                    :filter-option="false"
                    @change="getProductUOM($event, record)"
                    @blur="handleResetSearch"
                    @search="handleSearch"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option
                      v-for="item in inventories"
                      :key="item.id"
                      :value="`${item.id}.${item.product_id}`"
                      :disabled="selectedSKU.includes(item.sku)"
                    >
                      <div class="d-flex flex-column product-select">
                        <div>{{ item?.title }}</div>
                        <div class="sku-desc">
                          {{ 'SKU : ' + ellipsisText(item?.sku) }}
                        </div>
                      </div>
                    </a-select-option>
                  </a-select>
                  <template v-if="record?.availability < 1">
                    <a-alert
                      style="font-size: 13px"
                      message="Stok tidak tersedia"
                      type="error"
                      show-icon
                    />
                  </template>
                </template>
                <template slot="msrp" slot-scope="text">
                  <div>
                    Rp {{ toCurrency(text) }}
                  </div>
                </template>
                <template slot="selling_price" slot-scope="text, record, index">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      Rp
                    </div>
                    <a-input-number
                      v-model="record.selling_price"
                      class="nostep w-75"
                      :disabled="!record.catalog_id"
                      size="large"
                      autocomplete="off"
                      :formatter="value => format(value)"
                      :parser="value => parseLocaleNumber(value)"
                      placeholder="0"
                      @keypress="numberOnly"
                      @change="onChangeInputCart($event, index, 'selling_price')"
                    />
                  </div>
                </template>
                <template slot="product_discount" slot-scope="text, record, index">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      Rp
                    </div>
                    <a-input-number
                      v-model="record.product_discount"
                      class="nostep w-75"
                      :disabled="!record.catalog_id"
                      size="large"
                      autocomplete="off"
                      placeholder="0"
                      :formatter="value => formatterValidateMaxValue(value, record.selling_price)"
                      :parser="value => parseLocaleNumber(value, record)"
                      @change="onChangeInputCart($event, index, 'product_discount')"
                    />
                  </div>
                </template>
                <template slot="qty" slot-scope="text, record, index">
                  <div class="d-flex justify-items-center pt-2" style="gap: 10px;">
                    <a-tooltip trigger="click" :title="`Stok tersedia: ${record?.availability}`" color="red">
                      <a-input-number
                        v-model="record.quantity"
                        :disabled="!record.catalog_id"
                        size="large"
                        class="nostep"
                        autocomplete="off"
                        placeholder="0"
                        :max="9999"
                        style="width: 50%"
                        :formatter="value => formatterValidateMaxValue(value, 9999)"
                        :parser="value => parseLocaleNumber(value)"
                        @keypress="numberOnly"
                        @change="onChangeInputCart($event, index, 'quantity')"
                      />
                    </a-tooltip>
                    <a-select v-model="record.uom" class="w-100" size="large" :disabled="!record.catalog_id">
                      <a-select-option v-for="level in record.levels" :key="level">
                        {{ level }}
                      </a-select-option>
                    </a-select>
                  </div>
                </template>
                <template slot="total_price" slot-scope="text">
                  <div>
                    Rp {{ toCurrency(text) }}
                  </div>
                </template>
              </a-table>
            </div>

            <div class="mt-3">
              <a-row type="flex" :gutter="[16,16]" align="middle" class="mb-2">
                <a-col flex="auto" class="text-right">
                  Sub Total
                </a-col>
                <a-col flex="206px">
                  Rp {{ toCurrency(computedSubTotal) }}
                </a-col>
              </a-row>
              <a-row type="flex" :gutter="[16,16]" align="middle">
                <a-col flex="auto" class="text-right">
                  Diskon
                </a-col>
                <a-col flex="206px">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      Rp
                    </div>
                    <a-form-model-item class="m-0" label="" prop="totalDiscount">
                      <a-input-number
                        v-model="form.totalDiscount"
                        class="nostep w-100"
                        size="large"
                        autocomplete="off"
                        :formatter="value => formatterValidateMaxValue(value, computedSubTotal - form.totalVoucher)"
                        :parser="value => parseLocaleNumber(value)"
                        @change="onChangeDiscAndVoucherMaxValue($event, computedSubTotal - form.totalVoucher, 'totalDiscount')"
                      />
                    </a-form-model-item>
                  </div>
                </a-col>
              </a-row>
              <!-- <a-row type="flex" :gutter="[16,16]" align="middle">
                <a-col flex="auto" class="text-right">
                  Voucher
                </a-col>
                <a-col flex="206px">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      Rp
                    </div>
                    <a-form-model-item class="m-0" label="" prop="totalVoucher">
                      <a-input-number
                        v-model="form.totalVoucher"
                        class="nostep w-100"
                        size="large"
                        autocomplete="off"
                        :formatter="value => formatterValidateMaxValue(value, computedSubTotal - form.totalDiscount)"
                        :parser="value => parseLocaleNumber(value)"
                        @change="onChangeDiscAndVoucherMaxValue($event, computedSubTotal - form.totalDiscount, 'totalVoucher')"
                      />
                    </a-form-model-item>
                  </div>
                </a-col>
              </a-row> -->
              <a-row type="flex" :gutter="[16,16]" align="middle">
                <a-col flex="auto" class="text-right">
                  Biaya Pengiriman
                </a-col>
                <a-col flex="206px">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      Rp
                    </div>
                    <a-form-model-item class="m-0" label="" prop="shippingCost">
                      <a-input-number
                        v-model="form.shippingCost"
                        :disabled="!form.withCourier"
                        class="nostep w-100"
                        size="large"
                        autocomplete="off"
                        :formatter="value => format(value)"
                        :parser="value => parseLocaleNumber(value)"
                        @keypress="numberOnly"
                      />
                    </a-form-model-item>
                  </div>
                </a-col>
              </a-row>
              <a-row type="flex" :gutter="[16,16]" align="middle">
                <a-col flex="auto" class="text-right">
                  Asuransi Pengiriman
                </a-col>
                <a-col flex="206px">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      Rp
                    </div>
                    <a-form-model-item class="m-0" label="" prop="insuranceCost">
                      <a-input-number
                        v-model="form.insuranceCost"
                        :disabled="!form.withCourier"
                        class="nostep w-100"
                        size="large"
                        autocomplete="off"
                        :formatter="value => format(value)"
                        :parser="value => parseLocaleNumber(value)"
                        @keypress="numberOnly"
                      />
                    </a-form-model-item>
                  </div>
                </a-col>
              </a-row>
              <a-row
                style="font-size: 16px;"
                type="flex"
                :gutter="[16,16]"
                align="middle"
                class="mt-2"
              >
                <a-col flex="auto" class="text-right">
                  <strong>GRAND TOTAL</strong>
                </a-col>
                <a-col flex="206px">
                  <strong>Rp {{ toCurrency(computedGrandTotal) }}</strong>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </a-card>

      <a-card title="Data Pembeli" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="Nama Depan" prop="firstName">
              <a-input
                v-model="form.firstName"
                size="large"
                :max-length="25"
                autocomplete="off"
                placeholder="Masukan nama depan"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="Nama Belakang" prop="lastName" class="mb-0">
              <a-input
                v-model="form.lastName"
                size="large"
                :max-length="25"
                autocomplete="off"
                placeholder="Masukan nama belakang"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="Email" prop="email" class="mb-0">
              <a-input
                v-model="form.email"
                size="large"
                :max-length="50"
                autocomplete="off"
                placeholder="Masukan email"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="No Telp" prop="phoneNumber" class="mb-0">
              <a-input
                v-model="form.phoneNumber"
                size="large"
                :max-length="25"
                autocomplete="off"
                placeholder="Masukan no telp"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card title="Data Pengiriman" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="12" class="mt-3">
            <div class="border rounded p-3">
              <a-form-model-item
                label="Pengiriman"
                prop="withCourier"
                class="mb-0"
              >
                <a-radio-group
                  v-model.trim="form.withCourier"
                  name="withCourier"
                  class="radio-input"
                >
                  <a-radio class="text-note" :value="true">
                    Kurir
                  </a-radio>
                  <a-radio class="text-note" :value="false">
                    Self Pickup
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <template v-if="!!form.withCourier">
                <a-divider dashed class="my-2" />
                <a-row :gutter="24" class="mb-2">
                  <a-col :span="12">
                    <a-form-model-item
                      label="Pilih Kurir"
                      prop="courierName"
                      class="mb-0"
                    >
                      <a-select
                        v-model="form.courierName"
                        :filter-option="filterOptionCity"
                        size="large"
                        show-arrow
                        show-search
                      >
                        <a-select-option v-for="itemCourier in couriers" :key="itemCourier.id" :value="itemCourier.id" :title="itemCourier.courier_name">
                          {{ itemCourier.courier_name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item
                      label="Service"
                      prop="courierService"
                      class="mb-0"
                    >
                      <a-select
                        v-model="form.courierService"
                        :disabled="!form.courierName"
                        :filter-option="filterOptionCity"
                        size="large"
                        show-arrow
                        show-search
                        @focus="getShippingService"
                      >
                        <a-select-option v-for="(itemService, index) in courierServices" :key="index" :value="index" :title="itemService.service_name">
                          {{ [itemService?.service_name, itemService?.service_type].filter(item => !!item && item !== '').join(' - ') }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-divider dashed class="my-2" />
                <a-form-model-item label="Air Way Bill No." prop="awbNumber" class="mb-4">
                  <a-input
                    v-model="form.awbNumber"
                    :max-length="25"
                    size="large"
                    autocomplete="off"
                    placeholder="Masukan no air way bill"
                  />
                </a-form-model-item>
                <a-divider dashed class="my-2" />
                <a-form-model-item
                  label=""
                  prop="withLabelUrl"
                  class="mb-0"
                >
                  <a-radio-group
                    v-model.trim="form.withLabelUrl"
                    name="withLabelUrl"
                    class="radio-input"
                  >
                    <a-radio class="text-note" value="own">
                      Gen Own Label
                    </a-radio>
                    <a-radio class="text-note" value="url">
                      URL
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item
                  v-if="form.withLabelUrl === 'url'"
                  label=""
                  prop="shippingUrlLabel"
                  class="mb-4"
                  :validate-status="!isValidShippingUrlLabel ? 'error' : ''"
                >
                  <a-input
                    v-model="form.shippingUrlLabel"
                    size="large"
                    autocomplete="off"
                    placeholder="Masukan label url"
                  />
                  <span>
                    <a-alert
                      v-if="!isValidShippingUrlLabel"
                      type="error"
                      class="mt-2"
                    >
                      <template #description>
                        <p>URL yang anda masukan <b>tidak valid</b>, anda dapat <b>mengosongkan</b> label url jika tidak memiliki url shipping label</p>
                        <p class="mb-0">Contoh URL yang valid:</p>
                        <ul>
                          <li>https://example.com/label</li>
                          <li>www.example.com/label</li>
                          <li>example.com/label/12345</li>
                        </ul>
                      </template>
                    </a-alert>
                  </span>
                </a-form-model-item>
              </template>
            </div>
          </a-col>
          <a-col :span="12">
            <a-row v-if="form.withCourier" :gutter="24">
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.country')"
                  prop="country"
                  class="mb-0"
                >
                  <a-select
                    v-model="form.country"
                    size="large"
                    show-arrow
                    show-search
                    :filter-option="filterOptionCity"
                    :placeholder="`Select ${$t('distributor.country')}`"
                  >
                    <!-- <a-spin v-if="loadingCountries" slot="notFoundContent" size="small" /> -->
                    <a-select-option v-for="country in countries" :key="country.value" :value="country.value" :title="country.label">
                      {{ country.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.city')"
                  prop="city"
                  class="mb-0"
                >
                  <a-select
                    v-model="form.city"
                    size="large"
                    show-arrow
                    show-search
                    :filter-option="filterOptionCity"
                    :not-found-content="loadingCities ? undefined : null"
                    :disabled="!(!!form.country)"
                    :placeholder="`Select ${$t('distributor.city')}`"
                    @change="onChangeCity(false)"
                    @focus="getCities(false)"
                  >
                    <a-spin v-if="loadingCities" slot="notFoundContent" size="small" />
                    <a-select-option v-for="city in cities" :key="city.value" :value="city.value" :title="city.label">
                      {{ city.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.subdistrict')"
                  prop="subdistrict"
                  class="mb-0"
                >
                  <a-select
                    v-model="form.subdistrict"
                    size="large"
                    show-arrow
                    show-search
                    :disabled="!(!!form.city)"
                    :filter-option="filterOptionCity"
                    :not-found-content="loadingSubdistrict ? undefined : null"
                    :placeholder="`Select ${$t('distributor.subdistrict')}`"
                    @change="onChangeDistrict(false)"
                    @focus="getSubdistrict"
                  >
                    <a-select-option
                      v-if="!form.city.length"
                      key="novalid"
                      value=""
                      disabled
                      class="text-dark"
                    >
                      Harap Pilih Kota
                    </a-select-option>
                    <a-spin v-else-if="loadingSubdistrict && form.city.length" slot="notFoundContent" size="small" />
                    <a-select-option v-for="sub in subdistricts" :key="sub.value" :value="sub.value" :title="sub.label">
                      {{ sub.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.postal_code')"
                  prop="postcode"
                  class="mb-0"
                >
                  <a-input
                    v-model.trim="form.postcode"
                    size="large"
                    :placeholder="$t('distributor.postal_code')"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  label="Nama Jalan"
                  prop="addressLine1"
                  class="mb-0"
                >
                  <a-textarea
                    v-model="form.addressLine1"
                    size="large"
                    :auto-size="{ minRows: 2, maxRows: 9 }"
                    placeholder="Nama Jalan"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>

      <a-card title="Data Penagihan" class="w-100 mt-4" :bordered="false">
        <a-form-model-item
          label=""
          prop="sameWithShippingAddress"
          class="mb-0"
        >
          <a-checkbox
            v-model="form.sameWithShippingAddress"
            class=""
            :disabled="!form.withCourier"
            @change="scrollToBottom"
          >
            Gunakan alamat pengiriman untuk alamat penagihan
          </a-checkbox>
        </a-form-model-item>
        <a-row v-if="!form.sameWithShippingAddress">
          <a-col :span="12">
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.country')"
                  prop="billingCountry"
                  class="mb-0"
                >
                  <a-select
                    v-model="form.billingCountry"
                    size="large"
                    show-arrow
                    show-search
                    :filter-option="filterOptionCity"
                    :placeholder="`Select ${$t('distributor.country')}`"
                  >
                    <!-- <a-spin v-if="loadingCountries" slot="notFoundContent" size="small" /> -->
                    <a-select-option v-for="country in billingCountries" :key="country.value" :value="country.value" :title="country.label">
                      {{ country.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.city')"
                  prop="billingCity"
                  class="mb-0"
                >
                  <a-select
                    v-model="form.billingCity"
                    size="large"
                    show-arrow
                    show-search
                    :filter-option="filterOptionCity"
                    :not-found-content="loadingCities ? undefined : null"
                    :disabled="!(!!form.billingCountry)"
                    :placeholder="`Select ${$t('distributor.city')}`"
                    @change="onChangeCity(true)"
                    @focus="getCities(true)"
                  >
                    <a-spin v-if="loadingCities" slot="notFoundContent" size="small" />
                    <a-select-option v-for="city in billingCities" :key="city.value" :value="city.value" :title="city.label">
                      {{ city.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.subdistrict')"
                  prop="billingSubdistrict"
                  class="mb-0"
                >
                  <a-select
                    v-model="form.billingSubdistrict"
                    size="large"
                    show-arrow
                    show-search
                    :disabled="!(!!form.billingCity)"
                    :filter-option="filterOptionCity"
                    :not-found-content="loadingSubdistrict ? undefined : null"
                    :placeholder="`Select ${$t('distributor.subdistrict')}`"
                    @change="onChangeDistrict(true)"
                    @focus="getSubdistrict(true)"
                  >
                    <a-select-option
                      v-if="!form.billingCity.length"
                      key="novalid"
                      value=""
                      disabled
                      class="text-dark"
                    >
                      Harap Pilih Kota
                    </a-select-option>
                    <a-spin v-else-if="loadingSubdistrict && form.billingCity.length" slot="notFoundContent" size="small" />
                    <a-select-option v-for="sub in billingSubdistricts" :key="sub.value" :value="sub.value" :title="sub.label">
                      {{ sub.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  :label="$t('distributor.postal_code')"
                  prop="billingPostcode"
                  class="mb-0"
                >
                  <a-input
                    v-model.trim="form.billingPostcode"
                    size="large"
                    :placeholder="$t('distributor.postal_code')"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  label="Nama Jalan"
                  prop="billingAddressLine1"
                  class="mb-0"
                >
                  <a-textarea
                    v-model="form.billingAddressLine1"
                    size="large"
                    :auto-size="{ minRows: 2, maxRows: 9 }"
                    placeholder="Nama Jalan"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>
      <div ref="targetRef" />
      <div v-if="!isDetail" class="discount-footer shadow">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          :disabled="isLoading"
          @click="goBack"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          :disabled="!itemList.length > 0 || itemList.filter((obj) => obj.catalog_id && obj.quantity > 0)?.length !== itemList?.length || (!isValidShippingUrlLabel && form.withLabelUrl === 'url')"
          html-type="submit"
          type="primary"
          size="large"
          :loading="isLoading"
        >
          {{ $t('utils.save') }}
        </a-button>
      </div>
    </a-form-model>
    <ModalBulkItemImport
      :is-loading="isLoading"
      :visible="showModalBulkItemImport"
      :count-item-import="countItemImport"
      :count-success-import="countSuccessImport"
      :count-failed-import="countFailedImport"
      :on-close="() => showModalBulkItemImport = false"
      @handleSubmitModal="onNormalizeData"
    />
    <ModalFeedbackAfterImport
      :revised-data="listRevisedBulkItemImport"
      :is-loading="isLoading"
      :visible="showModalFeedbackAfterImport"
      :on-close="() => showModalFeedbackAfterImport = false"
    />
  </div>
</template>

<script>
import { createManualOrder } from '@/api/sales'
import { getCatalogListGR, getUom } from '@/api/inventory'
import { getPriceRange } from '@/api/price'
import { getShippingCourier, getShippingService } from '@/api/shipping'
import { getArea } from '@/api/data'
import { columnsCreateOrderManual } from '@/data/columns'
import { useInputNumber } from '@/composable/useInputNumber'
import { useDateTime } from '@/composable/useDateTime'
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import debounce from 'lodash/debounce'
import { v4 as uuidv4 } from 'uuid';
import { useCurrency } from '@/composable/useCurrency'
import ModalBulkItemImport from '@/views/manual-order/ModalBulkItemImport.vue'
import ModalFeedbackAfterImport from '@/views/manual-order/ModalFeedbackAfterImport.vue'

const PAYMENT_TYPE = [
  {
    method: 'mandiri_va”',
    payment_name: 'VA Bank Mandiri',
    payment_type: 'VA',
  },
  {
    method: 'bca_va',
    payment_name: 'VA Bank Central Asia',
    payment_type: 'VA',
  },
  {
    method: 'bri_va',
    payment_name: 'VA Bank BRI',
    payment_type: 'VA',
  },
  {
    method: 'permata_va',
    payment_name: 'VA Bank Permata',
    payment_type: 'VA',
  },
  {
    method: 'other_va',
    payment_name: 'VA Bank Lainnya',
    payment_type: 'VA',
  },
  {
    method: 'mandiri_transfer”',
    payment_name: 'Bank Transfer Mandiri',
    payment_type: 'BANK_TRANSFER',
  },
  {
    method: 'bca_transfer',
    payment_name: 'Bank Transfer Central Asia',
    payment_type: 'BANK_TRANSFER',
  },
  {
    method: 'bri_transfer',
    payment_name: 'Bank Transfer BRI',
    payment_type: 'BANK_TRANSFER',
  },
  {
    method: 'permata_transfer',
    payment_name: 'Bank Transfer Permata',
    payment_type: 'BANK_TRANSFER',
  },
  {
    method: 'other_transfer',
    payment_name: 'Bank Transfer Lainnya',
    payment_type: 'BANK_TRANSFER',
  },
]

export default {
  name: 'CreateDiscount',
  components: {
    TrashIcon,
    ModalBulkItemImport,
    ModalFeedbackAfterImport,
  },
  beforeRouteLeave(to, from, next) {
    // if (this.$route.query.discount_id) {
    //   delete this.$route.query.discount_id
    // }
    next()
  },
  setup() {
    const { format, parseLocaleNumber } = useCurrency()
    const { numberOnly, toNumber } = useInputNumber()
    const { parseISODate } = useDateTime()
    return {
      numberOnly,
      toNumber,
      parseISODate,
      format,
      parseLocaleNumber,
    }
  },
  data() {
    return {
      defaultTier: 1,
      readyTier: 1,
      currTier: 1,
      currQuota: 0,
      sellerId: [],
      idPromo: -1,
      dateFormat: 'DD MMM YYYY HH:mm',
      columns: columnsCreateOrderManual,
      isLoading: false,
      isDetail: false,
      fetching: false,
      endOpen: false,
      visible: false,
      areaAll: false,
      distributorAll: false,
      categoryAll: false,
      customerAll: false,
      itemList: [],
      warehouseList: [],
      inventories: [],
      paymentList: PAYMENT_TYPE,
      totalProduct: null,
      rules: {
        orderNumber: [{ required: false, trigger: 'change' }],
        paymentType: [{ required: true, trigger: 'change' }],
        orderDate: [{ required: true, trigger: 'change' }],
        firstName: [{ required: true, trigger: 'change' }],
        lastName: [{ required: true, trigger: 'change' }],
        email: [{ required: true, trigger: 'change' }],
        phoneNumber: [{ required: true, trigger: 'change' }],
        country: [{ required: true, trigger: 'change' }],
        city: [{ required: true, trigger: 'change' }],
        subdistrict: [{ required: true, trigger: 'change' }],
        postcode: [{ required: true, trigger: 'change' }],
        addressLine1: [{ required: true, trigger: 'change' }],
        warehouseId: [{ required: true, trigger: 'change' }],
        courierName: [{ required: true, trigger: 'change' }],
        courierService: [{ required: true, trigger: 'change' }],
        awbNumber: [{ required: false, trigger: 'change' }],
      },
      initVal: {},
      search: '',
      overlayStyle: {
        width: '100px',
      },
      params: {
        page: 1,
        size: 1000,
      },
      countries: [
        { value: "228", label: "Indonesia", code: "ID", currency_code: "IDR", currency_id: "111" },
      ],
      cities: [],
      subdistricts: [],
      billingCountries: [
        { value: "228", label: "Indonesia", code: "ID", currency_code: "IDR", currency_id: "111" },
      ],
      billingCities: [],
      billingSubdistricts: [],
      couriers: [],
      courierServices: [],
      loadingCities: false,
      loadingSubdistrict: false,
      form: {
        orderNumber: '',
        paymentType: '',
        orderDate: '',
        warehouseId: '',
        totalDiscount: 0,
        totalVoucher: 0,
        shippingCost: 0,
        insuranceCost: 0,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        country: '',
        city: '',
        subdistrict: null,
        postcode: '',
        addressLine1: '',
        withCourier: true,
        courierName: '',
        courierService: '',
        awbNumber: '',
        withLabelUrl: 'url',
        shippingUrlLabel: '',
        shippingOwnLabel: '',
        shippingFileLabel: '',
        sameWithShippingAddress: false,
        billingCountry: '',
        billingCity: '',
        billingSubdistrict: '',
        billingPostcode: '',
        billingAddressLine1: '',
      },
      subTotal: 0,
      grandTotal: 0,
      showModalBulkItemImport: false,
      listRevisedBulkItemImport: [],
      showModalFeedbackAfterImport: false,
      countItemImport: 0,
      countSuccessImport: 0,
      countFailedImport: 0,
      isValidShippingUrlLabel: true,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    business() {
      return Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
    },
    isPrinciple() {
      return ['business owner', 'supervisor', 'store operator', 'principle'].includes(this.$store.state.user.role_name?.toLowerCase())
    },
    selectedSKU() {
      return this.itemList.map((obj2) => obj2.sku)
    },
    computedSubTotal() {
      if (this.itemList.length) {
        const sumTotalPrice = this.itemList.reduce((sum, { total_price }) => { return sum + total_price }, 0)
        return sumTotalPrice
      }
      return 0
    },
    computedGrandTotal() {
      if (this.itemList.length) {
        const sumTotalPrice = this.itemList.reduce((sum, { total_price }) => { return sum + total_price }, 0)
        return sumTotalPrice - (parseInt(this.form?.totalDiscount || 0) + parseInt(this.form?.totalVoucher || 0)) + (parseInt(this.form?.shippingCost) || 0) + (parseInt(this.form?.insuranceCost) || 0)
      }
      return 0
    },
    computedValidSKU() {
      return this.itemList.filter((obj) => obj.catalog_id)?.length
    },
  },
  watch: {
    businessId() {
      this.init()
      this.getWarehouseList()
    },
    search: {
      deep: true,
      immediate: true,
      handler: function () {
        // if (value) {
        this.fetchInventory()
        // }
      },
    },
    'form.shippingUrlLabel': {
      deep: true,
      immediate: true,
      handler: debounce(function (value) {
        if (value) {
          this.isValidShippingUrlLabel = this.validateShippingLabelUrl(value)
        } else {
          this.isValidShippingUrlLabel = true
        }
      }, 500),
    },
    'form.withCourier': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.rules = {
            ...this.rules,
            country: [{ required: true, trigger: 'change' }],
            city: [{ required: true, trigger: 'change' }],
            subdistrict: [{ required: true, trigger: 'change' }],
            postcode: [{ required: true, trigger: 'change' }],
            addressLine1: [{ required: true, trigger: 'change' }],
            courierName: [{ required: true, trigger: 'change' }],
            courierService: [{ required: true, trigger: 'change' }],
            awbNumber: [{ required: false, trigger: 'change' }],
          }
        } else {
          this.form.sameWithShippingAddress = false
          this.rules = {
            ...this.rules,
            country: [{ required: false, trigger: 'change' }],
            city: [{ required: false, trigger: 'change' }],
            subdistrict: [{ required: false, trigger: 'change' }],
            postcode: [{ required: false, trigger: 'change' }],
            addressLine1: [{ required: false, trigger: 'change' }],
            courierName: [{ required: false, trigger: 'change' }],
            awbNumber: [{ required: false, trigger: 'change' }],
            courierService: [{ required: false, trigger: 'change' }],
            billingCountry: [{ required: true, trigger: 'change' }],
            billingCity: [{ required: true, trigger: 'change' }],
            billingSubdistrict: [{ required: true, trigger: 'change' }],
            billingPostcode: [{ required: true, trigger: 'change' }],
            billingAddressLine1: [{ required: true, trigger: 'change' }],
          }
        }
      },
    },
  },
  created() {
    this.init()
    this.getWarehouseList()
  },
  methods: {
    init: debounce(function() {
      this.fetchInventory()
      this.getShippingCourier()
    }, 500),
    onChangeWarehouse() {
      this.itemList = []
      this.fetchInventory()
    },
    validateShippingLabelUrl(url) {
      const urlRegex = /^(https?:\/\/)?(www\.)?(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}(\/[a-z0-9\-._~%!$&'()*+,;=:@]*)*(\?[a-z0-9\-._~%!$&'()*+,;=:@/?]*)?(#[a-z0-9\-._~%!$&'()*+,;=:@/?]*)?$/i;
      return urlRegex.test(url);
    },
    onNormalizeData(importedData) {
      this.countItemImport = 0
      this.countSuccessImport = 0
      this.countFailedImport = 0
      this.isLoading = true
      this.listRevisedBulkItemImport = []
      const normalizeData = []
      importedData.forEach((obj) => {
        const status = []
        const newObj = Object.fromEntries(
          Object.entries(obj).map(([key, val]) => [key.replaceAll(' ', '_').toLowerCase(), val]),
        );
        const isDuplicatedSKU = normalizeData.find((obj2) => obj2.sku === newObj.sku)
        if (isDuplicatedSKU) {
          return
        }
        if (!/^\d+$/.test(newObj.quantity) || !newObj?.sku || !newObj?.quantity) {
          this.countFailedImport = this.countFailedImport + 1
          status.push('data_not_valid')
        }
        if (status.length) {
          this.listRevisedBulkItemImport.push({
            ...newObj,
            status: status,
          })
          this.countItemImport = this.countItemImport + 1
          return
        }
        this.countItemImport = this.countItemImport + 1
        normalizeData.push(newObj)
      })
      this.onSubmitBulkItemImport(normalizeData)
    },
    onSubmitBulkItemImport(importedData) {
      const promises = []
      const tempItemList = this.itemList
      const { warehouseId } = this.form
      if (!warehouseId) {
        this.isLoading = false
        return
      }
      importedData.map((obj) => {
        const isDuplicatedSKU = tempItemList.find((obj2) => obj2.sku === obj.sku)
        if (isDuplicatedSKU) {
          return
        }
        const bodyValue = {
          business_id: this.businessId,
          warehouse_id: warehouseId,
          search: obj.sku,
          page: 1,
          limit: 20,
        }
        promises.push(
          getCatalogListGR(bodyValue),
        )
      })

      Promise.all(promises)
        .then(async (res) => {
          await Promise.all(res.map(async (obj, index) => {
            const { data, total_row } = obj.data
            if (total_row > 0) {
              if (total_row === 1) {
                const selecetedCatalog = data.at(0)
                const selectedImagePath = JSON.parse(selecetedCatalog.images).at(0)
                const newRecord = {
                  item_reference_id: tempItemList.length + index,
                  catalog_id: `${selecetedCatalog.id}.${selecetedCatalog.product_id}`,
                  catalog_title: selecetedCatalog.title,
                  quantity: importedData[index].quantity,
                  uom: importedData[index].uom,
                  sku: importedData[index].sku,
                  batch_number: '',
                  expired_date: null,
                  stock_type: 'AV',
                  base_quantity: '',
                  base_uom: '',
                  serial_number: '',
                  levels: [],
                  units: {},
                  availability: selecetedCatalog.availability,
                  showimg_url: selectedImagePath?.imagePath,
                }
                const { data: { data: uomLevels } } = await getUom({
                  id: selecetedCatalog.product_id,
                  params: {
                    business_id: this.businessId,
                  },
                })
                if (uomLevels) {
                  let levels = []
                  uomLevels.units.forEach((item, index) => {
                    levels.push(item.unit)
                    newRecord.units[item.unit] = {
                      numerator: item.numerator,
                      denumerator: item.denumerator,
                    }
                    if(item.numerator == 1 && item.denumerator == 1) newRecord.base_uom = item.unit
                  })
                  newRecord.levels = levels
                } else {
                  newRecord.uom = ''
                  newRecord.base_uom = ''
                  newRecord.levels = []
                  newRecord.units = {}
                }
                const isValidUOM = newRecord.levels.find((obj) => obj === newRecord.uom)
                if (isValidUOM) {
                  const { data: { data: resPriceRange } } = await getPriceRange({
                    product_id: selecetedCatalog.product_id,
                    business_id: this.businessId,
                  })
                  if (resPriceRange.length > 0) {
                    const tempPrice = resPriceRange.find((obj) => obj.catalog_id === selecetedCatalog.id)?.price
                    const msrp = tempPrice.find((obj) => obj.uom === newRecord.uom)?.value
                    newRecord.price = tempPrice
                    newRecord.msrp = msrp
                    newRecord.selling_price = importedData[index].selling_price
                    newRecord.product_discount = importedData[index].product_discount
                    newRecord.total_price = (newRecord.selling_price -  parseInt(newRecord.product_discount || 0)) * newRecord.quantity
                  }
                  const isExistOnInventories = this.inventories.find((obj) => obj.id === selecetedCatalog.id)
                  if (!isExistOnInventories) {
                    this.inventories.push(selecetedCatalog)
                  }
                  this.itemList.push(newRecord)
                  this.countSuccessImport = this.countSuccessImport + 1
                  this.onChangeQuantity(`${newRecord.quantity}`, newRecord)
                } else {
                  this.countFailedImport = this.countFailedImport + 1
                  this.listRevisedBulkItemImport.push({
                    ...importedData[index],
                    status: ['uom_not_valid'],
                  })
                }
              } else {
                this.countFailedImport = this.countFailedImport + 1
                this.listRevisedBulkItemImport.push({
                  ...importedData[index],
                  status: ['sku_not_found'],
                })
              }
            } else {
              this.countFailedImport = this.countFailedImport + 1
              this.listRevisedBulkItemImport.push({
                ...importedData[index],
                status: ['sku_not_found'],
              })
            }
          }))
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.showModalBulkItemImport = false
          this.isLoading = false
          if (this.listRevisedBulkItemImport.length) {
            this.showModalFeedbackAfterImport = true
            this.$notification.warning({
              message: 'Beberapa data tidak valid',
            })
          } else {
            this.$notification.success({
              message: 'Semua Data Berhasil di Import',
            })
          }
        })
    },
    scrollToBottom() {
      if (!this.form.sameWithShippingAddress) {
        setTimeout(() => {
          this.$refs.targetRef.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }, 50)
        this.rules = {
          ...this.rules,
          billingCountry: [{ required: true, trigger: 'change' }],
          billingCity: [{ required: true, trigger: 'change' }],
          billingSubdistrict: [{ required: true, trigger: 'change' }],
          billingPostcode: [{ required: true, trigger: 'change' }],
          billingAddressLine1: [{ required: true, trigger: 'change' }],
        }
      } else {
        this.rules = {
          ...this.rules,
          billingCountry: [{ required: false, trigger: 'change' }],
          billingCity: [{ required: false, trigger: 'change' }],
          billingSubdistrict: [{ required: false, trigger: 'change' }],
          billingPostcode: [{ required: false, trigger: 'change' }],
          billingAddressLine1: [{ required: false, trigger: 'change' }],
        }
      }
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    async getShippingCourier() {
      const { data } = await getShippingCourier()
      this.couriers = data?.data || []
    },
    async getShippingService() {
      const { data } = await getShippingService({
        courier_id: this.form.courierName,
      })
      this.courierServices = data?.data || []
    },
    filterOptionCity(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    disabledDate(current) {
      // if (!current) return current && current > this.$moment().subtract(2, 'day')
      // return (current && current.valueOf() > this.$moment().subtract(2, 'day'))
      return (current && current > this.$moment().endOf('day')) || (current && current.valueOf() < this.$moment().subtract(3, 'day'))
    },
    getWarehouseList() {
      this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          business_id: this.businessId,
          warehouse_id: this.$store.state.user.restriction_base === 'Warehouse'
            ? this.$route.query.warehouse_id
            : undefined,
        })
        .then(res => {
          this.warehouseList = res
          if(res?.length == 1) this.form.warehouseId = res[0].id
        })
        .catch(err => {
          console.error(err)
          this.warehouseList = []
        })
    },
    async getCities(isBilling) {
      this.loadingCities = true
      await getArea({
        businessId: this.$route.query.business_id,
        params: {
          parentId: isBilling
            ? this.form.billingCountry
            : this.form.country,
        },
      })
      .then(res => {
        const list = res?.data?.list
        if (list) {
          if (isBilling) {
            this.billingCities = list
          } else {
            this.cities = list
          }
        }
      })
      .catch(() => {})
      .finally(() => this.loadingCities = false)
    },
    onChangeCity(isBilling) { // val, option
      this.loadingSubdistrict = true
      if (isBilling) {
        this.billingSubdistricts = []
        this.form.billingSubdistrict = ''
      } else {
        this.subdistricts = []
        this.form.subdistrict = ''
      }
    },
    onChangeDistrict(isBilling) { // val, option
      if (isBilling) {
        const findSelectedSubdistrict = this.billingSubdistricts?.find((obj) => obj.value === this.form.billingSubdistrict)
        this.form.billingPostcode = findSelectedSubdistrict?.postcode
      } else {
        const findSelectedSubdistrict = this.subdistricts?.find((obj) => obj.value === this.form.subdistrict)
        this.form.postcode = findSelectedSubdistrict?.postcode
      }
    },
    async getSubdistrict(isBilling) {
      this.loadingSubdistrict = true
      await getArea({
        businessId: this.$route.query.business_id,
        params: {
          parentId: isBilling
            ? this.form.billingCity
            : this.form.city,
        },
      })
      .then(res => {
        const list = res?.data?.list
        if (list) {
          if (isBilling) {
            this.billingSubdistricts = list
          } else {
            this.subdistricts = list
          }
        }
      })
      .catch(() => {})
      .finally(() => this.loadingSubdistrict = false)
    },
    formatterValidateMaxValue(value, max) {
      if (value === '-') {
        return ''
      }
      if (value > max) {
        value = max
      }
      return this.format(value)
    },
    onChangeDiscAndVoucherMaxValue(value, max, field) {
      if (value > max) {
        value = max
      }
      this.form[field] = value
    },
    onChangeInputCart(value, index, field) {
      const currentItemList = this.itemList[index]
      if (field === 'product_discount' && value > currentItemList.selling_price) {
        value = currentItemList.selling_price
      }
      if (field === 'quantity' && value > 9999) {
        value = 9999
      }
      currentItemList[field] = value
      currentItemList.total_price = (currentItemList.selling_price -  parseInt(currentItemList.product_discount || 0)) * currentItemList.quantity
    },
    ellipsisText(text) {
      if(!text) return '-'
      return text.length > 29 ? `${text.substring(0, 26)}...` : text
    },
    async onSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          const {
            firstName,
            lastName,
            email,
            phoneNumber,
            addressLine1,
            postcode,
            country,
            city,
            subdistrict,
            shippingCost,
            insuranceCost,
            awbNumber,
            orderNumber,
            warehouseId,
            orderDate,
            paymentType,
            totalDiscount,
            totalVoucher,
            shippingUrlLabel,
            withLabelUrl,
            withCourier,
            courierService,
            sameWithShippingAddress,
            billingAddressLine1,
            billingPostcode,
            billingCountry,
            billingCity,
            billingSubdistrict,
          } = this.form

          const { params } = this.$route
          const selectedShippingCountry = this.countries.find((obj) => obj.value === country)
          const selectedShippingCity = this.cities.find((obj) => obj.value === city)
          const selectedShippingSubdistrict = this.subdistricts.find((obj) => obj.value === subdistrict)
          let payloadBillingAddress
          let payloadShippingAddress = {
            line1: addressLine1,
            line2: null,
            postal_code: postcode,
            country: selectedShippingCountry?.label,
            province: selectedShippingCity?.label.split(' - ')[0],
            city: selectedShippingCity?.label.split(' - ')[1],
            district: selectedShippingSubdistrict?.label.split(' - ')[0],
            sub_district: selectedShippingSubdistrict?.label.split(' - ')[1],
            country_id: country,
            city_id: city,
            sub_district_id: subdistrict,
          }
          if (sameWithShippingAddress) {
            payloadBillingAddress = payloadShippingAddress
          } else {
            const selectedBillingCountry = this.billingCountries.find((obj) => obj.value === billingCountry)
            const selectedBillingCity = this.billingCities.find((obj) => obj.value === billingCity)
            const selectedBillingSubdistrict = this.billingSubdistricts.find((obj) => obj.value === billingSubdistrict)
            payloadBillingAddress = {
              line1: billingAddressLine1,
              line2: null,
              postal_code: billingPostcode,
              country: selectedBillingCountry?.label,
              province: selectedBillingCity?.label.split(' - ')[0],
              city: selectedBillingCity?.label.split(' - ')[1],
              district: selectedBillingSubdistrict?.label.split(' - ')[0],
              sub_district: selectedBillingSubdistrict?.label.split(' - ')[1],
              country_id: billingCountry,
              city_id: billingCity,
              sub_district_id: billingSubdistrict,
            }
          }
          let selectedCourier
          if (withCourier) {
            selectedCourier = this.courierServices[courierService]
          }
          let finalLabelGenerated = null
          if (withLabelUrl === 'url' && shippingUrlLabel) {
            finalLabelGenerated = shippingUrlLabel
          }
          const SPU_SHIPPING_DETAIL = {
            id: uuidv4(),
            cost_type: null,
            courier: 'SPU',
            service_code: 'SPU',
            service_type: '',
            service_name: '',
            shipping_cost: {
              value: 0,
              currency: 'IDR',
            },
            insurance_cost: {
              value: 0,
              currency: 'IDR',
            },
            remarks: null,
            shipping_label_url: null,
            awb: "",
          }
          const selectedShippingDetail = {
            id: uuidv4(),
            cost_type: selectedCourier?.cost_type,
            courier: selectedCourier?.courier_name,
            service_code: selectedCourier?.service_code,
            service_type: selectedCourier?.service_type,
            service_name: selectedCourier?.service_name,
            shipping_cost: {
              value: parseInt(shippingCost || 0),
              currency: 'IDR',
            },
            insurance_cost: {
              value: parseInt(insuranceCost || 0),
              currency: 'IDR',
            },
            remarks: null,
            shipping_label_url: finalLabelGenerated,
            awb: awbNumber,
          }
          const finalShippingMethod = withCourier ? selectedShippingDetail : SPU_SHIPPING_DETAIL
          const selectedPayment = this.paymentList.find((obj) => obj.method === paymentType)
          let discountList = []
          let voucherList = []
          if (totalDiscount) {
            discountList.push({
              code: `test_discount_hardcode_${this.$moment().format('DDMMYYYY')}`,
              name: `TEST DISCOUNT HARDCODE ${this.$moment().format('DDMMYYYY')}`,
              type: 'transaction',
              value: parseInt(totalDiscount || 0),
              source: 'seller',
            })
          }
          if (totalVoucher) {
            voucherList.push({
              code: `test_voucher_hardcode_${this.$moment().format('DDMMYYYY')}`,
              name: `TEST VOUCHER HARDCODE ${this.$moment().format('DDMMYYYY')}`,
              type: 'transaction',
              value: parseInt(totalVoucher || 0),
              source: 'seller',
            })
          }
          const payload = {
              customer_data: {
                id: uuidv4(),
                entity_name: `${firstName} ${lastName}`,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
              },
              billing_data: {
                id: uuidv4(),
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                address: payloadBillingAddress,
              },
              destination_data: {
                id: uuidv4(),
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                address: withCourier ? payloadShippingAddress : payloadBillingAddress,
              },
              shipping_data: finalShippingMethod,
              promotions: {
                discounts: discountList,
                vouchers_used: voucherList,
              },
              payment: {
                term: 'CBD',
                method: paymentType,
                payment_name: selectedPayment?.payment_name,
                payment_type: selectedPayment?.payment_type,
              },
              warehouse_id: warehouseId,
              po_number: '',
              order_number: orderNumber,
              created_at: this.$moment.utc(orderDate).format(this.$moment.HTML5_FMT.DATETIME_LOCAL_SECONDS), 
              order_item: this.itemList.map((obj) => {
                const selectedPrice = obj.price.find((obj2) => obj2.uom === obj.uom)
                return {
                  catalog_id: obj.catalog_id.split('.')[0],
                  catalog_sku: obj.sku,
                  title: obj.catalog_title,
                  product_id: obj.catalog_id.split('.')[1],
                  uom: obj.uom,
                  vat: null,
                  price_id: selectedPrice?.price_id,
                  discount: {
                    currency: 'IDR',
                    value: parseInt(obj.product_discount || 0),
                  },
                  selling_price: {
                    currency: 'IDR',
                    value: obj.selling_price,
                  },
                  image_url: obj.showimg_url,
                  qty: parseInt(obj.quantity || 0),
                  status: 'OPEN',
                }
              }),
          }

          await createManualOrder({
            business_id: this.businessId,
            channel_id: params.id,
            data: payload,
          })
          .then(({ data }) => {
            this.$notification.success({
              message: 'Berhasil membuat order',
              description: `nomor order: ${data?.data?.order_number}`,
            })
            this.$router.go(-1)
          })
          .catch(err => {
            console.error(err)
            this.$notification.error({
              message: 'Gagal membuat order',
              description: err?.response?.data?.message || 'Terjadi kesalahan',
            })
          })
          .finally(() => this.isLoading = false)
        }
      });
    },
    async fetchInventory() {
      this.isLoading = true
      const { query, params } = this.$route
      const { warehouseId } = this.form
      if (!warehouseId) {
        this.isLoading = false
        return
      }
      const bodyValue = {
        business_id: this.businessId,
        search: this.search,
        page: 1,
        limit: 20,
        warehouse_id: warehouseId,
        is_bundling_included: true,
      }
      this.isLoading = false
      await getCatalogListGR(bodyValue)
        .then(({ data: { data: response } }) => {
          this.inventories = response
        })
        .catch(err => {
          this.inventories = []
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    handleSearch: debounce(function(e) {
      this.search = e
    }, 500),
    handleResetSearch() {
      this.search = ''
    },
    async getProductUOM(value, record) {
      record.quantity = ''
      record.selling_price = ''
      record.product_discount = ''
      record.total_price = 0
      const findCatalog = this.inventories.find(item => {
        return item.id === value.split('.')[0]
      })
      record.catalog_title = findCatalog?.title || ''
      record.sku = findCatalog?.sku || ''
      record.showimg_url = findCatalog?.showimg_url || ''
      record.availability = findCatalog?.availability || 0
      this.fetching = true
      await getUom({
        id: value.split('.')[1],
        params: {
          business_id: this.businessId,
        },
      })
      .then(({ data: { data: response } }) => {
        let levels = []
        response.units.forEach((item, index) => {
          levels.push(item.unit)
          record.units[item.unit] = {
            numerator: item.numerator,
            denumerator: item.denumerator,
          }

          if(index == 0) record.uom = item.unit
          if(item.numerator == 1 && item.denumerator == 1) record.base_uom = item.unit
        })
        record.levels = levels
      })
      .catch(() => {
        record.uom = ''
        record.base_uom = ''
        record.levels = []
        record.units = {}
        record.availability = 0
      })
      .finally(() => {
        this.fetching = false
      })
      getPriceRange({
        business_id: this.businessId,
        product_id: value.split('.')[1],
      })
      .then(({ data: { data: response } }) => {
        const tempPrice = response.find((obj) => obj.catalog_id === value.split('.')[0])?.price
        const msrp = tempPrice.find((obj) => obj.uom === record.uom)?.value
        record.price = tempPrice
        record.msrp = msrp
        record.selling_price = msrp
      })
      .catch(() => {
        record.price = null
      })
      .finally(() => {
        this.fetching = false
        this.$forceUpdate()
      })
    },
    onChangeQuantity(event, record) {
      record.base_quantity = +event * +record.units[record.uom].numerator
    },
    onDeleteItem(record) {
      const filter = this.itemList.filter(v => {return v.item_reference_id !== record.item_reference_id})
      const temp = filter.map((v, i) => {
        const data = {
          ...v,
          item_reference_id: typeof v.item_reference_id === 'string' ? v.item_reference_id : i + 1,
        }
        return data
      })
      this.itemList = temp
    },
    addItem() {
     const arr = this.itemList
     const temp = {
        item_reference_id: arr.length + 1,
        catalog_id: '',
        catalog_title: '',
        quantity: '',
        uom: '',
        sku: '',
        batch_number: '',
        expired_date: null,
        stock_type: 'AV',
        base_quantity: '',
        base_uom: '',
        serial_number: '',
        levels: [],
        units: {},
        price: {},
        msrp: null,
        total_price: null,
        selling_price: '',
        product_discount: null,
     }
     arr.push(temp)
     this.itemList = arr
    },
    goBack() {
      this.$router.back({
        path: '/inventory/goods-receipt',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss">
@import './create.module.scss';
.group-product-select .ant-select-dropdown-menu-item-group-title {
  height: fit-content !important;
  line-height: normal !important;
  border-top: 1px solid rgba(0, 0, 0, 0.45);
  padding-top: 10px;
}
</style>
<style lang="scss" scoped>
.ant-select-lg .ant-select-selection--single .product-select {
  white-space: nowrap !important;
}
.product-select {
  white-space: pre-wrap !important;
  .sku-desc {
    font-size: 12px;
    font-weight: 500;
  }
}

.ant-input-number-handler-wrap .remove-arrow {
  width: 0px !important;
}
</style>
